import { navigate } from 'gatsby'
import React from 'react'
import Box from 'components/Box'
import Container from 'components/Container'
import Head from 'components/Head'
import Layout from 'components/Layout'
import View from 'components/View'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import Auth from 'features/auth/components/Auth'
import useEventPageVisited from 'hooks/use-event-page-visited'

export default function SignIn() {
  useEventPageVisited('Sign in')
  const globalContext = useGlobalContext()

  if (globalContext?.isAuthed) {
    navigate(paths.HOME_AUTHED, { replace: true })
  }

  return (
    <Layout className="SignIn" data-test-id="sign-in">
      <View flush>
        <Head
          title="Log in to Bold — Variety of online fitness classes for older adults"
          description="Bold is your home for personalized, science-backed workouts shown to reduce fall risk. Assess your strength, mobility, and balance with a free 1-minute test."
        />
        <Container size="xlarge">
          <Box flush>
            <Auth type="sign-in" />
          </Box>
        </Container>
      </View>
    </Layout>
  )
}
